<template>
  <div data-saved-population-list style="position: relative">
    <div class="flex" style="position: absolute; top: -64px; right: 0">
      <router-link
        class="flex items-center color-secondary"
        :to="{ name: 'PopulationBuilder' }"
        ><font-awesome-icon icon="plus-square" class="fs-36" />
        <span data-create-drug-group-btn class="rfs-m-2-l"
          >Create a New Population</span
        ></router-link
      >
    </div>
    <portal to="before-content" v-if="!breakpoints.greaterThanMd">
      <div class="filter-bar rfs-p-8-l">
        <font-awesome-icon icon="filter" />
        <button
          type="button"
          class="btn btn-text"
          :class="[{ active: smScreenControlsShow }]"
          @click="toggleControls()"
        >
          Filter Populations <font-awesome-icon icon="angle-down" />
        </button>
      </div>
    </portal>
    <!-- Result data -->
    <transition-group name="fade">
      <div key="1" v-if="populations !== undefined && populations.length === 0">
        <p class="card bg-warning-contrast color-white fw-600" >
          <font-awesome-icon class="" icon="exclamation-circle" />
          There are no populations.
        </p>
      </div>
      <div class="grid gap-4 xs:grid-cols-1 sm:grid-cols-2" key="2" v-if="populations !== undefined && populations.length > 0">
        <template v-for="(pop, i) in getPopulations">
          <div class="card" :key="i">
            <div class="card-meta-grid">
              <div class="card-meta-label">
                <!-- <div class="grid gap-6 grid-cols-2 xl:grid-cols-2" key="2"> -->
                  <div class="fs-20 break-word card-title">{{ pop.populationName }}</div>
                  <!-- <div>Population ID: {{ pop.id }}</div> -->
                <!-- </div> -->
              </div>
              <div class="card-top-right-corner">
                <router-link
                  :to="{
                    name: 'PopulationBuilder',
                    params: { popid: pop.id }
                  }"
                  manage-population-edit-btn
                >
                  <font-awesome-icon style="cursor: pointer" icon="edit" />
                </router-link>
              </div>
            </div>
            <!-- <div class="card-subtitle">
              Characteristics
            </div> -->
            <div class="card-body">
              <div class="card-meta-grid">
                  <div class="grid gap-4 grid-cols-2 xl:grid-cols-2">
                      <div v-if="pop.characteristics[0] !== undefined || pop.characteristics[1] !== undefined || pop.characteristics[2] !== undefined">
                        <div class="card-subtitle mb-5" v-if="pop.characteristics[0] !== undefined || pop.characteristics[1] !== undefined"> Diagnosis </div>
                        <div class="mb-10 fs-14 pl-10" v-if="pop.characteristics[0] !== undefined || pop.characteristics[1] !== undefined">
                          <div class="grid gap-14 grid-cols-2">
                            <div class="width-80 text-overflow-dot">
                              {{ (pop.characteristics[0] !== undefined && pop.characteristics[0].length > 0)
                                  ? `${pop.characteristics[0][0]}`
                                  : ((pop.characteristics[1] !== undefined && pop.characteristics[1].length > 0)
                                    ? `${pop.characteristics[1][0]}` : ``) }}
                            </div>
                            <div class="width-20">
                              {{ (pop.characteristics[0] !== undefined && pop.characteristics[0].length > 1)
                                  ? `+${pop.characteristics[0].length - 1} more`
                                  : ((pop.characteristics[1] !== undefined && pop.characteristics[1].length > 1)
                                      ? `+${pop.characteristics[1].length - 1} more` : ``) }}
                            </div>
                          </div>
                        </div>
                        <div class="card-subtitle mb-5" v-if="pop.characteristics[2] !== undefined"> Comorbidity </div>
                        <div class="fs-14 pl-10" v-if="pop.characteristics[2] !== undefined">
                          <div class="grid gap-14 grid-cols-2">
                            <div class="width-80 text-overflow-dot">
                              {{ (pop.characteristics[2] !== undefined && pop.characteristics[2].length > 0) ? `${pop.characteristics[2][0]}` : `` }}
                            </div>
                            <div class="width-20">
                              {{ (pop.characteristics[2] !== undefined && pop.characteristics[2].length > 1)
                                  ? `+${pop.characteristics[2].length - 1} more` : `` }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="card-subtitle mb-5" v-if="isTreamentRegimentExist(i)"> Treatment Regimen </div>
                        <div class="fs-14 pl-10" v-for="(characteristic, j) in pop.characteristics" :key="j">
                          <div class="grid gap-14 grid-cols-2" v-if="j > 2 && characteristic !== undefined">
                            <div class="width-80 text-overflow-dot">
                              {{ (characteristic !== undefined && characteristic.length > 0) ? `${characteristic[0]}` : `` }}
                            </div>
                            <div class="width-20">
                              {{ (characteristic !== undefined && characteristic.length > 1)
                                  ? `+${characteristic.length - 1} more` : `` }}
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </transition-group>
    <!-- Load More -->
    <transition-group name="fade">
      <div v-if="populations !== undefined && populations.length > 0 && loadMoreVisible"
        key="3" class="text-center rfs-m-8">
        <button
          data-load-more
          class="btn btn-sm btn-primary rfs-m-4-l"
          @click="loadMore">
          <span>Load More</span>
        </button>
      </div>
    </transition-group>
    <!-- Loading window -->
    <transition-group name="fade">
      <div v-if="loadingData" key="2" class="text-center rfs-m-8">
        <loader />
      </div>
    </transition-group>
    <portal to="after-content">
      <div
        class="right-side-bar"
        :class="{
          'sm-screen-contols-show':
            smScreenControlsShow && !breakpoints.greaterThanMd,
          'sm-screen-contols': !breakpoints.greaterThanMd
        }"
      >
        <div
          class="rsb-content auto-scroll"
          :class="{ 'has-footer': !breakpoints.greaterThanMd }"
        >
          <form @submit.prevent="">
            <h3 class="rfs-18 fw-sb rfs-m-4-b">Narrow Results by Search Term</h3>
            <div
              class="single-input-search"
            >
              <text-input
                :value="searchTerm"
                fieldLabel="Search"
                type="search"
                class="rfs-m-t-b rfs-m-4-b"
                fieldNote="Search by Population Name, Principal Diagnosis, Comorbidity, CCS code, ICD10 code, NDC, Description, GPI, GPI Description, AHFS code, UCS code, Medication Group Name, Procedures code, CPT, HCPCS, ICD10P"
                @input="onFilterChange"
              />

            </div>
          </form>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
  import TextInput from '@/components/base/forms/text_input.vue';
  import card from '@/components/base/card';

  export default {
    name: 'PopulationManagement',
    components: {
      TextInput,
      card
    },
    data() {
      return {
        smScreenControlsShow: false,
        offset: 0,
        pageSize: 10,
        loadingData: true,
        populations: undefined,
        visiblePopulations: undefined,
        loadMoreVisible: true,
        searchTerm: ''
      };
    },
    computed: {
      getPopulations() {
        return this.populations;
      },
    },
    methods: {
       toggleControls() {
        this.smScreenControlsShow = !this.smScreenControlsShow;
      },
     isTreamentRegimentExist(index){
        let characteristics=this.populations[index].characteristics;
        for(let i=2;i<characteristics.length;i++){
         if(characteristics[i] !== undefined){
          return true;
         }
        }
        return false;
      },
      onFilterChange(v) {
        this.searchTerm = v;
        if ((v !== undefined && v.length >= 3)
            || v === undefined || v.length === 0) {
          this.offset = 0;
          this.getPopulationList();
        }
      },
      getPopulationList() {
        const payload = {'searchString': this.searchTerm, 'offset': this.offset, 'pageSize': this.pageSize};
        this.$store.dispatch('populationManagement/searchPopulationsList', payload).then(() => {
          let d = this.$store.getters['populationManagement/getSavedList'];
          this.loadMoreVisible = (d.length === 0 || d.length < this.pageSize) ? false : this.loadMoreVisible;
          this.updatePopulations(d);
        });
      },
      loadMore() {
        this.offset += 10;
        const payload = {'searchString': this.searchTerm, 'offset': this.offset, 'pageSize': this.pageSize};
        this.$store.dispatch('populationManagement/searchPopulationsList', payload).then(() => {
          let d = this.$store.getters['populationManagement/getSavedList'];
          if(d.length > 0) {
            this.loadMoreVisible = (d.length < this.pageSize) ? false : this.loadMoreVisible;
            this.updatePopulations(d, true);
          } else {
            this.loadMoreVisible = false;
          }
        });
      },
      updatePopulations(d, isLoadMore = false) {
        let dMapped = d.map((pop) => {
          let ret = {};
          ret.id = pop.id;
          ret.populationName = pop.filterData.populationName;

          // geeting filter data
          const popCharacteristics = new Array(7);
          const rules = pop.filterData.rules;
          for(const rule of rules) {
            const ruleName = rule.name;
            // creating valueCode description array
            const valueCodes = [];
            if (rule.valueCodes !== undefined && rule.valueCodes.length > 0) {
              rule.valueCodes.forEach(valueCode => {
                valueCodes.push(valueCode.description);
              });
            }
            switch (ruleName) {
              case 'principalDiagnosisCcs':
              case 'principalDiagnosisIcd10':
              case 'principalDiagnosis':
                if (popCharacteristics[0] !== undefined && popCharacteristics[0].length > 0) {
                  popCharacteristics[0] = popCharacteristics[0].concat(valueCodes);
                } else {
                  popCharacteristics[0] = valueCodes;
                }
                break;
              case 'diagnosisGroup':
                popCharacteristics[1] = valueCodes;
                break;
              case 'comorbidityIcd10':
              case 'comorbidity':
                if (popCharacteristics[2] !== undefined && popCharacteristics[2].length > 0) {
                  popCharacteristics[2] = popCharacteristics[2].concat(valueCodes);
                } else {
                  popCharacteristics[2] = valueCodes;
                }
                break;
              case 'therapeuticClassAhfs':
              case 'therapeuticClass':
              case 'therapeuticClassUsc':
                if (popCharacteristics[3] !== undefined && popCharacteristics[3].length > 0) {
                  popCharacteristics[3] = popCharacteristics[3].concat(valueCodes);
                } else {
                  popCharacteristics[3] = valueCodes;
                }
                break;
              case 'drugGroups':
                popCharacteristics[4] = valueCodes;
                break;
              case 'ndc':
                popCharacteristics[5] = valueCodes;
                break;
              case 'procedureCodes':
                popCharacteristics[6] = valueCodes;
                break;
              default:
                //we are still looping through rules so don't return here
                //continue parsing response
                break;
            }
          }
          ret.characteristics = popCharacteristics;

          return ret;
        });

        if(isLoadMore) {
          const newPopulations = (dMapped !== undefined && dMapped.length > 0) ? dMapped : [];
          this.populations = this.populations.concat(newPopulations);
        } else {
          this.populations = (dMapped !== undefined && dMapped.length > 0) ? dMapped : [];
        }
        this.loadingData = false;
      }
    },
    mounted() {
      this.getPopulationList();
    }
  };
</script>

<style>
  .break-word {
    word-break: break-word;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-5 {
    margin-bottom: 5px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-20 {
    font-size: 20px;
  }
  .pl-10 {
    padding-left: 10px;
  }
  .width-80 {
    width: 80%;
  }
  .width-20 {
    width: 80%;
  }
  .text-overflow-dot {
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1024px) {
    #appContent {
      min-height: calc(100vh - 220px);
      margin-top: 114px;
    }
  }
</style>
